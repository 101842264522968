<template>
  <div class="range-counter col-sm-4 col-md-4">
    <label for="">{{ title }}</label>
    <b-col class="px-0 custom-control-inline">
      <b-btn variant="btn-sm mr-1 mt-1 mb-1" @click="decrement"><i class="far fa-minus"></i></b-btn>
      <div>
        <b-form-input 
          type="text"
          :value="labelForValue"
          aria-describedby="input-live-help" 
          readonly 
          trim>
        </b-form-input>
        <b-form-input hidden type="text" v-model="currentValue" :value="currentValue"></b-form-input>
      </div>
      <b-btn variant="btn-sm ml-1 mt-1 mb-1" @click="increment"><i class="far fa-plus"></i></b-btn>
    </b-col>
    <div v-show="error" class="pl-3 invalid">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    domId: {
      type: String
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 9
    },
    title: {
      type: String,
      required: true
    },
    labels: {
      type: Function
    },
    value: {
      type: Number|String,
      default: 0
    },
    invalidMessage: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      rawValue: parseInt(this.value) || this.min,
    }
  },
  methods: {
    increment() {
      if (this.currentValue < this.max) {
        this.currentValue += 1
      }
    },
    decrement() {
      if (this.currentValue > this.min) {
        this.currentValue -= 1
      }
    }
  },
  computed: {
    currentValue: {
      get() {
        return this.rawValue
      },
      set(v) {
        this.rawValue = v
        this.$emit('update', v)
      }
    },
    labelForValue() {
      if (this.labels) {
        return this.optionsForSelect[this.currentValue].l
      }
      return this.currentValue
    },
    optionsForSelect() {
      var options = []
      if (this.labels) {
        for (var i = 0; i < this.max + 1; i++) {
          const option = { l: this.labels(i, 'select'), v: i }
          options.push(option)
        }
      }
      return options
    },
    error() {
      if(this.invalidMessage != ''){
        return this.invalidMessage
      }
      return null
    }
  },
  watch: {
    value(newValue) {
      this.rawValue = parseInt(newValue) || this.min
    }
  }
}
</script>
