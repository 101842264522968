<template>
  <div>
    <div class="row">
      <counter 
        title="Number Of Bedrooms"
        dom-id="bedrooms_widget"
        :labels="bedroomLabels"
        :value="ourBedrooms"
        @update="onBedroomsUpdate" />
      <counter
        title="Number Of Bathrooms"
        dom-id="bathrooms_widget"
        :min="1"
        :value="ourBathrooms"
        @update="onBathroomsUpdate" />
      <counter
        title="Area"
        dom-id="sqfeet_widget"
        :max="11"
        :labels="areaLabels"
        :value="ourSquareFeet"
        @update="onAreaUpdate" />
    </div>
  </div>
</template>
<script>
import Counter from '@/shared/counter_widget.vue'

export default {
  props: {
    squareFeet: {
      type: Number,
      default: 0
    },
    bedrooms: {
      type: Number,
      default: 0
    },
    bathrooms: {
      type: Number,
      default: 1,
      validator(value) {
        if (value < 1) {
          return 1
        }
        return value
      }
    }
  },
  components: { Counter },
  data() {
    return {
      ourBathrooms: this.bathrooms,
      ourBedrooms: this.bedrooms,
      ourSquareFeet: this.squareFeet,
      areas: [
        '800 sq.ft', '801-1500 sq.ft', '1501-2500 sq.ft', '2501-3000 sq.ft',
        '3001-3500 sq.ft', '3501-4000 sq.ft', '4001-4500 sq.ft', '4501-5000 sq.ft',
        '5001-5500 sq.ft', '5501-6000 sq.ft', '6001-6500 sq.ft', '6501-7000 sq.ft'
      ],
      submitStatus: null
    }
  },
  methods: {
    areaLabels(index, context, first, last) {
      if (index == null) {
        return ''
      }
      if (context == 'label') {
        if (!first && !last) {
          return ''
        }
      }
      return this.areas[index]
    },
    bedroomLabels(index, context, first, last) {
      if (index == null) {
        return ''
      }
      if (index == 0) {
        return 'Studio'
      }
      return index
    },
    emitUpdate() {
      this.$emit('update', {
        bathrooms: this.ourBathrooms,
        bedrooms: this.ourBedrooms,
        square_feet: this.ourSquareFeet
      })
    },
    onBathroomsUpdate(newValue) {
      this.ourBathrooms = newValue
      this.emitUpdate()
    },
    onBedroomsUpdate(newValue) {
      this.ourBedrooms = newValue
      this.emitUpdate()
    },
    onAreaUpdate(newValue) {
      this.ourSquareFeet = newValue
      this.emitUpdate()
    }
  },
  watch: {
    bedrooms(newValue) {
      this.ourBedrooms = newValue
    },
    bathrooms(newValue) {
      this.ourBathrooms = newValue
    },
    squareFeet(newValue) {
      this.ourSquareFeet = newValue
    }
  }
}
</script>
